import moment from 'moment-timezone';
import memoizeOne from 'memoize-one';
import isString from 'lodash/isString';

import React, { Fragment, cloneElement } from 'react';
import styled, { css } from 'styled-components';

import {
  LoadingOutlined,
  LikeOutlined,
  BookOutlined,
  BulbOutlined,
} from '@ant-design/icons';

import AntdButton from 'antd/lib/button';
import AntdSteps from 'antd/lib/steps';
import AntdDivider from 'antd/lib/divider';
import AntdResult from 'antd/lib/result';

import Admin from 'hive-admin';
import PageSingle from 'hive-admin/src/components/PageSingle';

import { Link, Redirect } from 'react-router-dom';

import './PageSingleVisual.less';

import Popover, {
  Content as PopoverContent,
} from './Popover';

import Types from '../common/modules/types';

const Steps = styled(AntdSteps)`
  margin-top: 35px;
  margin-bottom: 20px;
  .ant-steps-item-active {
    .ant-steps-item-title {
      color: ${({ theme }) => theme.less.primaryColor} !important;
    }
  }
`;

const Step = styled(AntdSteps.Step)`

`;

const StepTitle = styled.div`
  cursor: pointer;
  ${({ disabled }) => disabled && css`
    pointer-events: none;
  `}
`;

const ActionsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  .ant-btn {
    margin: 0px !important;
    margin-top: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  [data-is-tablet="true"] & {
    .ant-btn {
      width: 100% !important;
    }
  }
`;

const ActionsSide = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  [data-is-tablet="true"] & {
    width: 100%;
  }
`;

const ResultHTML = ({ icon, iconMap, disabled, ...props }) => {
  const IconComponent = iconMap[icon] || icon;
  const iconRender = (
    !IconComponent
      ? <></>
      : isString(IconComponent)
        ? IconComponent
        : <IconComponent />
  );
  return (
    <AntdResult
      icon={iconRender}
      {...props}
    />
  );
};

export const Result = styled(ResultHTML)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0px;
  .ant-result-title {
    font-size: 28px;
    font-weight: 600;
  }
  .ant-result-subtitle {
    font-size: 16px;
  }
  .ant-result-extra {
    text-align: inherit;
    width: 100%;
    ${({ disabled }) => disabled && css`
      filter: grayscale(100%);
      opacity: 0.8;
      cursor: not-allowed;
      > * {
        pointer-events: none !important;
      }
    `}
  }
`;

Result.defaultProps = {
  status: 'info',
  iconMap: {
    loading: LoadingOutlined,
    like: LikeOutlined,
    book: BookOutlined,
    bulb: BulbOutlined,
  },
};

const HeaderInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
`;

const HeaderInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 5px;
  min-width: 125px;
  margin-right: 10px;
  margin-top: 15px;
`;

const HeaderInfoItemLabel = styled.div`
  font-size: 14px;
  text-align: center;
  flex: 1;
  opacity: 0.5;
`;

const HeaderInfoItemValue = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

// const HeaderInfoSeparator = styled.div`
//   height: 30px;
//   width: 100%;
//   border-bottom: 1px solid ${({ theme }) => theme.less.borderColor};
// `;

export function renderResult(
  title,
  subTitle = null,
  icon = null,
  extra = null,
  { key = 'form', ...config } = {},
) {
  return (
    <Result
      key={key}
      title={title || null}
      subTitle={subTitle}
      icon={icon}
      extra={extra}
      {...config}
    />
  );
}

const SavePromptAndProceedActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const SavePromptAndProceedAction = styled(AntdButton)`
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

export function SavePromptAndProceed({ onClick, children, ...props }) {
  const { actions, form, data } = props;
  if (
    data
    && data.status === 'DRAFT'
    && actions
    && form
    && form.isFieldsTouched()
  ) {
    const saveAction = props.actions.find(({ name }) => name === 'save');
    if (saveAction && saveAction.onClick) {
      return (
        <Popover
          trigger="click"
          content={(
            <PopoverContent title="Save changes first?">
              <SavePromptAndProceedActions>
                <SavePromptAndProceedAction
                  size="small"
                  onClick={() => onClick()}
                >
                  Discard
                </SavePromptAndProceedAction>
                <SavePromptAndProceedAction
                  size="small"
                  type="primary"
                  onClick={() => {
                    saveAction
                      .onClick({ ...props, ...saveAction }, true)
                      .then(() => onClick())
                      .catch(() => { });
                  }}
                >
                  Save
                </SavePromptAndProceedAction>
              </SavePromptAndProceedActions>
            </PopoverContent>
          )}
        >
          {cloneElement(children, { onClick: () => { } })}
        </Popover>
      );
    }
  }
  return cloneElement(children, { onClick });
}

export function renderActionWithSavePromptAndProceed(props, instance) {
  return (
    <SavePromptAndProceed {...props} onClick={instance.handleClick}>
      {instance.renderAction(props)}
    </SavePromptAndProceed>
  );
}

export default class PageSingleVisual extends PageSingle {
  static config = {
    ...PageSingle.config,
    ClassName: 'PageSingleVisual',
    style: {},
    getFormProps: props => ({
      'data-page-single-form': true,
      ...props.formProps,
    }),
  }

  static defaultProps = {
    ...PageSingle.defaultProps,
    steps: [],
  }

  getFieldsMemoized = memoizeOne((data, viewer, fields) => (
    fields.filter(field => (
      !field.isAvailable
        ? true
        : field.isAvailable(data, viewer)
    ))
  ))

  getFields() {
    return this.getFieldsMemoized(
      this.props.data,
      this.props.viewer,
      this.props.fields,
    );
  }

  getActiveStepMemoized = memoizeOne((steps, pagePath) => (
    steps.find(({ path }) => path === pagePath)
  ))

  getActiveStep() {
    return this.getActiveStepMemoized(
      this.props.steps,
      this.props.page.path,
    );
  }

  renderStep(
    {
      index,
      path,
      onClick,
      getIsDisabled,
      getIcon,
      getRedirect,
      getTitle,
      getDescription,
      getStatus,
      renderContent,
      ...step
    },
    active,
  ) {
    if (active) {
      const redirect = getRedirect && getRedirect(this.props);
      if (redirect) {
        return (
          <Redirect key="redirect" to={redirect} />
        );
      }
    }
    return (
      <Step
        key={path}
        {...step}
        description={(
          getDescription
            ? getDescription(this.props)
            : step.description
        )}
        icon={getIcon ? getIcon(this.props) : undefined}
        status={getStatus ? getStatus(this.props) : undefined}
        title={(
          <SavePromptAndProceed
            {...this.props}
            onClick={() => onClick && onClick(this.props)}
          >
            <StepTitle
              disabled={
                !onClick || (getIsDisabled && getIsDisabled(this.props))
              }
              className="visual-step-title"
            >
              {getTitle ? getTitle(this.props) : step.title}
            </StepTitle>
          </SavePromptAndProceed>
        )}
      />
    );
  }

  renderHeaderStats = () => {
    const { data, viewer } = this.props;
    if (!data) {
      return null;
    }
    return (
      <>
        {
          [
            [
              {
                label: 'ID',
                value: data.visualId || data.visualTempId,
              },
              {
                label: 'Owner',
                value: (
                  data.organizationData?.name
                  ? (
                      viewer?.role === 'CONTENT_MANAGER'
                      ? data.organizationData?.name
                      : (
                          <Link
                            to={`/organizations/${
                              data.organizationData._id
                            }`}
                          >
                            {data.organizationData?.name}
                          </Link>
                        )
                    )
                  : 'Public'
                ),
              }, {
                label: 'Status',
                value: Types.VISUAL_STATUS_MAP[data.status].label, // TODO
              }, {
                label: 'Created',
                value: moment(data.createdAt).format('MMM DD, YYYY'),
              }, {
                label: 'Updated',
                value: moment(data.updatedAt).format('MMM DD, YYYY'),
              },
            ].filter(item => (
              item.label !== 'Owner'
              || ['ADMIN', 'CONTENT_MANAGER'].includes(viewer?.role)
            )),
            [
              ...[
                [
                  'Country',
                  'country',
                  undefined,
                  () => Types.COUNTRIES_MAP[data.country]?.name || 'N/A',
                ],
                [
                  'Marketing Type',
                  'marketingType',
                ],
                [
                  'Goal',
                  'goal',
                ],
                [
                  'Promotion Type',
                  'promotionType',
                  () => data.goal !== 'PROMOTION',
                ],
                [
                  'Season',
                  'season',
                ],
              ].reduce(
                (
                  agr,
                  [
                    label,
                    key,
                    getIsHidden,
                    getRender,
                  ],
                ) => {
                  if (!getIsHidden || !getIsHidden(data)) {
                    agr.push({
                      label,
                      value: (
                        getRender
                        ? getRender(data)
                        : (data[`${key}Label`] || '-')
                      ),
                    });
                  }
                  return agr;
                },
                []
              ),
            ],
            [
              ...[
                ['Brand', 'brandData'],
                ['Category', 'categoryData'],
                ['Retailer', 'retailerData'],
              ].map(([label, key]) => ({
                label,
                value: data[key]?.name || '-',
              })),
            ],
          ]
          .reduce(
            (agr, items) => {
              agr[0].push(...items);
              return agr;
            },
            [[]],
          )
          .map((items, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <HeaderInfo key={`${index}`}>
              {items.map(({ label, value }) => (
                <HeaderInfoItem key={label}>
                  <HeaderInfoItemLabel>{label}</HeaderInfoItemLabel>
                  <HeaderInfoItemValue>{value}</HeaderInfoItemValue>
                </HeaderInfoItem>
              ))}
            </HeaderInfo>
          ))
        }
        {/* <HeaderInfoSeparator /> */}
      </>
    );
  }

  renderHeader() {
    const activeStep = this.getActiveStep();
    return (
      <Fragment key="header">
        {super.renderHeader()}
        <div>
          {this.renderHeaderStats(this.props)}
        </div>
        <Steps current={activeStep.index}>
          {this.props.steps.map(step => this.renderStep(
            step,
            step === activeStep,
          ))}
        </Steps>
      </Fragment>
    );
  }

  renderBottomActions() {
    const actionsLeft = [];
    const actionsRight = [];
    this.props.actions.forEach((action) => {
      if (action.section === 'bottom') {
        if (action.right) {
          actionsRight.push(action);
        } else {
          actionsLeft.push(action);
        }
      }
    });
    return actionsLeft.length || actionsRight.length
      ? (
        <div key="actions-bottom" className="actions actions-bottom">
          <AntdDivider />
          <ActionsWrapper>
            <ActionsSide data-actions-side="left">
              {
                actionsLeft
                  .map(action => action.render(this.getStatefullProps()))
              }
            </ActionsSide>
            <ActionsSide data-actions-side="right">
              {
                actionsRight
                  .map(action => action.render(this.getStatefullProps()))
              }
            </ActionsSide>
          </ActionsWrapper>
        </div>
      )
      : null;
  }

  renderFormUsingGetFields(extras = {}) {
    return cloneElement(super.renderForm(), {
      fields: this.getFields(),
      ...extras,
    });
  }

  renderForm() {
    const activeStep = this.getActiveStep();
    if (!activeStep || !activeStep.renderContent) {
      return this.renderFormUsingGetFields();
    }
    return activeStep.renderContent(
      this.props,
      (...args) => this.renderFormUsingGetFields(...args),
      renderResult,
    );
  }
}

Admin.addToLibrary(
  'PageSingleVisual',
  config => PageSingleVisual.create(config),
);
