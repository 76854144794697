import tests from '../helpers/tests';

const PAGES = [
  // {
  //   path: 'import',
  //   title: 'Import',
  //   fields: [
  //     ...[
  //       ['import/categories', 'Import Categories'],
  //       ['import/brands', 'Import Brands'],
  //       ['import/retailers', 'Import Retailers'],
  //     ].map(([path, title]) => (['ActionWithRequest', {
  //       name: `dev-${path}`,
  //       title,
  //       // eslint-disable-next-line no-unused-vars
  //       getRequestConfig: props => ({
  //         url: `development/${path}`,
  //         method: 'POST',
  //       }),
  //     }])),
  //   ],
  // },
  {
    path: 'other',
    title: 'Other',
    fields: [
      ['ActionWithRequest', {
        name: 'sync-video-sessions',
        title: 'Sync Video Sessions',
        getRequestConfig: _props => ({
          url: 'videosessions/sync',
          method: 'POST',
        }),
      }],
      ['ActionWithRequest', {
        name: 'reset-visual-ids',
        title: 'Reset Visual Ids',
        getRequestConfig: _props => ({
          url: 'development/reset-visual-ids',
          method: 'POST',
        }),
      }],
    ],
  },
];

export default [
  'Group',
  {
    id: 'devtools',
    icon: 'code',
    title: 'Dev Tools',
    skip: [tests.viewerIsNotThehiveRsAdmin],
    pages: PAGES.reduce(
      (agr, { title, path, fields }) => {
        agr.push([
          'PageSingle',
          {
            path: `/devtools/${path}`,
            title: `Dev Tools / ${title}`,
            label: title,
            headerTitle: title,
            renderHeaderTitle: () => title,
            hidden: false,
            activity: 'edit',
            url: '/development',
            redirect: [['redirect.unauthorized']],
            fields,
            actions: [],
            skip: [tests.viewerIsNotThehiveRsAdmin],
          },
        ]);
        return agr;
      },
      [],
    ),
  },
];
