/* eslint-disable import/prefer-default-export */

// import omit from 'lodash/omit';

import React from 'react';
import styled from 'styled-components';

import {
  // RightOutlined,
  LeftOutlined,
  // DownloadOutlined,
  // ExclamationOutlined,
} from '@ant-design/icons';

import Admin from 'hive-admin';
import Query from 'hive-admin/src/components/Query';

import AntdInput from 'antd/lib/input';
import AntdButton from 'antd/lib/button';
import AntdMessage from 'antd/lib/message';

// import AntdButton from 'antd/lib/button';

// import ActionSave from 'hive-admin/src/components/ActionSave';

// import Input from 'hive-admin/src/components/Input/Input';

import Types from '../../common/modules/types';

import copyToClipboard from '../../helpers/copyToClipboard';

import tests from '../../helpers/tests';

import { DownloadReport } from '../../components/DownloadReport';

// import {
//   renderActionWithSavePromptAndProceed,
// } from '../../components/PageSingleVisual';

const {
  viewerIsAdminOrContentManager,
  viewerIsNotAdminNorContentManager,
  isViewerCustomerWhichCanManageOrganization,
  isViewerCustomerWhichCanManageVisuals,
  isViewerVisualCreator,
  isVisualProcessing,
} = tests;

// const IconExclamation = styled(ExclamationOutlined)`
//   color: ${({ theme }) => theme.less.errorColor} !important;
//   i { color: ${({ theme }) => theme.less.errorColor} !important; }
// `;

// const ActionIconRight = styled(RightOutlined)`
//   margin-right: -5px;
//   margin-left: 5px;
// `;

const ActionIconLeft = styled(LeftOutlined)`
  margin-right: 5px;
  margin-left: -5px;
`;

// const ActionIconDownload = styled(DownloadOutlined)`
//   margin-right: -5px;
//   margin-left: 5px;
// `;

// const ActionPopconfirmWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   min-width: 250px;
// `;

// const ActionPopconfirmButton = styled(AntdButton)`
//   margin-top: 10px;
// `;

// export const actionSave = ['ActionSave', {
//   section: 'bottom',
//   skip: [
//     props => props.data && props.data.status !== 'DRAFT',
//   ],
// }];

// export const actionSaveAndReload = ['ActionSaveAndReload', {
//   section: 'bottom',
//   skip: [
//     props => props.data && props.data.status !== 'DRAFT',
//   ],
// }];

export const actionSaveContent = ['ActionSaveAndReload', {
  section: 'bottom',
  skip: [
    props => props.data && props.data.status !== 'NEW',
    isVisualProcessing,
  ],
}];

export const actionSaveResults = ['ActionSaveAndReload', {
  section: 'bottom',
  skip: [
    props => props.data && props.data.status !== 'PROCESSING',
    isVisualProcessing,
  ],
}];

export const actionDelete = ['ActionDelete', {
  section: 'bottom',
  disabled: [
    ['condition.not', ['condition.or', [
      viewerIsAdminOrContentManager,
      isViewerCustomerWhichCanManageOrganization,
      isViewerCustomerWhichCanManageVisuals,
      isViewerVisualCreator,
    ]]],
  ],
  skip: [
    props => (
      (props.viewer && ['ADMIN', 'CONTENT_MANAGER'].includes(props.viewer.role))
      ? false
      : (props.data && props.data.status !== 'NEW')
    ),
    isVisualProcessing,
  ],
}];

// export const actionSwitchToContentBack = ['Action', {
//   name: 'switchToContentBack',
//   section: 'bottom',
//   ghost: true,
//   title: (
//     <>
//       <ActionIconLeft />
//       Content
//     </>
//   ),
//   onClick: props => props.history.replace(
//     `/projects/${props.data._id}/content`,
//   ),
//   renderAction: renderActionWithSavePromptAndProceed,
// }];

export const actionSwitchBackTo = ['ActionWithRequest', {
  name: 'switchBackTo',
  section: 'bottom',
  ghost: true,
  title: (
    <>
      <ActionIconLeft />
      Back
    </>
  ),
  popconfirm: (props, _action) => ({
    title: (
      <>
        Switch back to:
        <div
          style={{
            marginTop: '5px',
            marginBottom: '-10px',
          }}
        >
          <AntdButton
            onClick={() => props.onClick({
              ...props,
              switchBackTo: 'content',
            })}
            size="small"
            type="primary"
            // ghost
          >
            Content
          </AntdButton>
          &nbsp;
          {
            props.data?.status === 'PROCESSING'
            ? null
            : (
                <AntdButton
                  onClick={() => props.onClick({
                    ...props,
                    switchBackTo: 'processing',
                  })}
                  size="small"
                  type="primary"
                  // ghost
                >
                  Processing
                </AntdButton>
              )
          }
        </div>
      </>
    ),
    // okText: 'Confirm',
    okButtonProps: { style: { display: 'none' } },
    cancelButtonProps: { style: { display: 'none' } },
    // onCancel: null,
    // onConfirm: null,
    // icon: <IconDelete />,
    icon: null,
  }),
  // renderAction: renderActionWithSavePromptAndProceed,
  disabled: [
    viewerIsNotAdminNorContentManager,
  ],
  getRequestConfig: props => ({
    url: `visuals/${props.data._id}/actions/back?to=${props.switchBackTo}`,
    data: {},
    method: 'POST',
  }),
  handleSuccess: (data, props) => props.reload(),
  // handleSuccess: (data, props) => {
  //   props.history.replace(
  //     `/visuals/${props.data._id}/content`,
  //   );
  // },
  skip: [
    props => !props.data || props.data.status === 'NEW',
    isVisualProcessing,
  ],
}];

// export const actionSwitchToVariations = ['Action', {
//   name: 'switchToVariations',
//   section: 'bottom',
//   right: true,
//   ghost: true,
//   title: (
//     <>
//       Variations
//       <ActionIconRight />
//     </>
//   ),
//   onClick: props => props.history.replace(
//     `/projects/${props.data._id}/variations`,
//   ),
//   renderAction: renderActionWithSavePromptAndProceed,
// }];

// export const actionSwitchToVariationsBack = ['Action', {
//   name: 'switchToVariationsBack',
//   section: 'bottom',
//   ghost: true,
//   title: (
//     <>
//       <ActionIconLeft />
//       Variations
//     </>
//   ),
//   onClick: props => props.history.replace(
//     `/projects/${props.data._id}/variations`,
//   ),
//   renderAction: renderActionWithSavePromptAndProceed,
// }];

// export const actionSwitchToPlanogram = ['Action', {
//   name: 'switchToPlanogram',
//   section: 'bottom',
//   right: true,
//   ghost: true,
//   title: (
//     <>
//       Planogram
//       <ActionIconRight />
//     </>
//   ),
//   onClick: props => props.history.replace(
//     `/projects/${props.data._id}/planogram`,
//   ),
//   renderAction: renderActionWithSavePromptAndProceed,
// }];

// export const actionSwitchToPlanogramBack = ['Action', {
//   name: 'switchToPlanogramBack',
//   section: 'bottom',
//   ghost: true,
//   title: (
//     <>
//       <ActionIconLeft />
//       Planogram
//     </>
//   ),
//   onClick: props => props.history.replace(
//     `/projects/${props.data._id}/planogram`,
//   ),
// }];

// export const actionSwitchToFinalize = ['Action', {
//   name: 'switchToFinalize',
//   section: 'bottom',
//   right: true,
//   ghost: true,
//   title: (
//     <>
//       Finalize
//       <ActionIconRight />
//     </>
//   ),
//   onClick: props => props.history.replace(
//     `/projects/${props.data._id}/finalize`,
//   ),
//   skip: [props => props.data && props.data.status === 'REPORT'],
//   renderAction: renderActionWithSavePromptAndProceed,
// }];

// export const actionSwitchToReport = ['Action', {
//   name: 'switchToReport',
//   section: 'bottom',
//   right: true,
//   ghost: true,
//   title: (
//     <>
//       Report
//       <ActionIconRight />
//     </>
//   ),
//   onClick: props => props.history.replace(
//     `/projects/${props.data._id}/finalize`,
//   ),
//   skip: [props => props.data && props.data.status !== 'REPORT'],
//   renderAction: renderActionWithSavePromptAndProceed,
// }];

export const actionSubmit = ['ActionSaveAndReload', {
  name: 'submit',
  section: 'bottom',
  right: true,
  title: 'Submit',
  ghost: false,
  popconfirm: (props, action) => (
    !props.data?.organization
    ? null
    : {
        title: (
          <>
            Are you ready to submit?
            {
                !props.data?.charged
              ? (
                  <>
                    <br />
                    <Query
                      client={props.client}
                      url={`organizations/${props.data.organization}`}
                    >
                      {({ response }) => (
                        <>
                          {
                            props.viewer?.role === 'CUSTOMER'
                          ? (
                              <>
                                One credit will be deducted from your
                                <br />
                                balance.
                              </>
                            )
                          : (
                              <>
                                One credit will be deducted from
                                <br />
                                the organization&apos;s balance.
                              </>
                            )
                          }
                          <br />
                          <br />
                          <p
                            style={{
                              marginTop: '-15px',
                              marginBottom: '0px',
                              opacity: 0.7,
                            }}
                          >
                            {
                              `Current Balance: ${
                                response?.data
                                ? response.data.creditBalance
                                : '-'
                              }`
                            }
                          </p>
                        </>
                      )}
                    </Query>
                  </>
                )
              : (
                  <>
                    <br />
                    Credit balance has already been
                    <br />
                    deducted for this submission.
                  </>
                )
            }
          </>
        ),
        okButtonProps: { type: 'primary' },
        okText: 'Confirm',
        cancelButtonProps: { },
        onConfirm: action.handleClick,
        // icon: <IconDelete />,
        icon: null,
      }
  ),
  disabled: [
    ['condition.not', ['condition.or', [
      viewerIsAdminOrContentManager,
      isViewerCustomerWhichCanManageOrganization,
      isViewerCustomerWhichCanManageVisuals,
    ]]],
  ],
  getRequestConfig: (props, data) => ({
    url: `visuals/${props.data._id}/actions/submit`,
    data: { ...data },
    method: 'POST',
  }),
  handleSuccess: (data, props) => props.reload(),
  skip: [props => props.data && props.data.status !== 'NEW'],
}];

export const actionCreateReport = ['ActionSaveAndReload', {
  name: 'createReport',
  section: 'bottom',
  right: true,
  title: 'Create Report',
  disabled: [],
  getRequestConfig: (props, data) => ({
    url: `visuals/${props.data._id}/actions/report`,
    data: { ...data },
    method: 'POST',
  }),
  handleSuccess: (data, props) => props.reload(),
  skip: [
    viewerIsNotAdminNorContentManager,
    isVisualProcessing,
  ],
  // skip: [
  //   props => props.data && props.data.status !== 'PROCESSING',
  //   viewerIsNotAdminNorContentManager,
  // ],
}];

export const actionDuplicate = ['ActionWithRequest', {
  name: 'duplicateVisual',
  section: 'bottom',
  ghost: true,
  icon: 'copy',
  title: 'Duplicate',
  popconfirm: {
    title: (
      <AntdInput
        type="text"
        placeholder="New Visual Name"
        id="duplicate-new-name-input"
      />
    ),
    okButtonProps: { type: 'primary' },
    okText: 'Confirm',
    cancelButtonProps: { },
    // icon: <IconDelete />,
    icon: null,
  },
  // renderAction: renderActionWithSavePromptAndProceed,
  // disabled: [viewerIsNotAdminNorContentManager],
  getRequestConfig: props => ({
    url: `visuals/${props.data._id}/duplicate`,
    data: {
      name: document.getElementById('duplicate-new-name-input').value,
    },
    method: 'POST',
  }),
  handleSuccess: (data, props) => props.history.push(
    `/visuals/${data._id}/content`,
  ),
  // skip: [props => !props.data],
  skip: [
    props => !props.data,
    ['condition.not', ['condition.or', [
      viewerIsAdminOrContentManager,
      ['condition.and', [
        props => !!props.data.organization,
        isViewerCustomerWhichCanManageOrganization,
      ]],
      ['condition.and', [
        props => !!props.data.organization,
        isViewerCustomerWhichCanManageVisuals,
      ]],
      isViewerVisualCreator,
    ]]],
    isVisualProcessing,
  ],
}];

export const actionFillReportFromJson = ['Action', {
  name: 'fillReportFromJson',
  section: 'bottom',
  right: true,
  title: 'Import',
  disabled: [],
  ghost: true,
  // eslint-disable-next-line arrow-body-style, no-unused-vars
  popconfirm: (props, action) => {
    return {
      title: (
        <AntdInput.TextArea
          type="text"
          placeholder="Paste JSON here"
          style={{
            height: '300px',
            width: '250px',
            fontFamily: 'monospace',
            fontSize: 11,
          }}
          id="fill-report-from-json-input"
        />
      ),
      onConfirm: () => {
        let { value: json } = (
          document.getElementById('fill-report-from-json-input')
        );
        try {
          json = JSON.parse(json);
          const values = {};
          Types.REPORT_VALUES_LIST.forEach((item) => {
            const importKey = item.getImportKey(props.data.marketingType);
            if (importKey) {
              let value = json[importKey];
              if (typeof value === 'string') {
                value = parseFloat(value.replace(/[^.0-9]/g, ''), 10);
              } else if (typeof value === 'number') {
                value = parseFloat(value, 10);
              }
              if (Number.isFinite(value)) {
                values[item.id] = value;
              }
            }
          });
          props.form.setFields(Object.keys(values).reduce(
            (agr, key) => {
              agr[key] = {
                value: values[key],
                touched: true,
              };
              return agr;
            },
            {},
          ));
          props.form.validateFields();
        } catch (error) {
          Admin.showMessage('Invalid JSON', 'error', 3);
        }
      },
    };
  },
  skip: [
    props => props.data && props.data.status !== 'PROCESSING',
    viewerIsNotAdminNorContentManager,
  ],
}];

export const actionExportAnnotations = ['Action', {
  name: 'actionExportAnnotations',
  title: 'Export Annotations',
  right: true,
  ghost: true,
  section: 'bottom',
  skip: [
    props => props.data && ![
      'PROCESSING',
      'REPORT',
    ].includes(props.data.status),
    viewerIsNotAdminNorContentManager,
    // isVisualProcessing,
  ],
  onClick: (props) => {
    const visual = {
      ...props.data,
      ...props.form.getFieldsValue(),
    };
    visual.marketingKind = Types.getMarketingKind(visual.marketingType);
    Object.assign(visual, Types.getSpecifiedLabels(visual));
    const errors = Types.getAllAnnotationErrors(
      visual.annotations,
      visual,
      allAnnotations => (
        allAnnotations.filter(annotation => !annotation.contentKey)
      ),
    );
    if (!errors.count) {
      const annotationsExport = Types.getAnnotationsExport(visual);
      copyToClipboard(
        JSON.stringify(annotationsExport, null, '  '),
        'Annotations export copied to clipboard!',
      );
      return;
    }
    // eslint-disable-next-line no-console
    console.log('errors on actionCopyAnnotationsExport:', errors);
    // eslint-disable-next-line max-len
    AntdMessage.error('Some annotations are missing or invalid. Please check and try again');
  },
}];

export const actionExportProcessingResponse = ['Action', {
  name: 'actionExportProcessingResponse',
  title: 'Export Results',
  right: true,
  ghost: true,
  section: 'bottom',
  skip: [
    props => props.data && ![
      'PROCESSING',
      'REPORT',
    ].includes(props.data.status),
    viewerIsNotAdminNorContentManager,
    props => (props.data?.processingResponse ? false : true),
  ],
  onClick: (props) => {
    copyToClipboard(
      JSON.stringify(props.data.processingResponse, null, '  '),
      'Processing Response export copied to clipboard!',
    );
  },
}];

export const actionDownloadReport = ['Action', {
  name: 'export-report',
  title: 'Export PDF Report',
  ghost: true,
  right: true,
  skip: [
    props => props?.data?.status !== 'REPORT',
  ],
  renderAction: (props, action) => {
    const { data: visual } = props;
    return (
      <DownloadReport
        fileName={`${visual.name}.pdf`}
        data={visual}
        includeCaseStudy={
          visual?.organizationData
          ? visual.organizationData.pdfReportIncludeCaseStudy
          : true
        }
        logoSrc={visual.organizationData?.logo?.src}
      >
        {
          ({ loading }) => (
            loading
              ? action.renderAction({
                ...props,
                title: 'Loading...',
                disabled: true,
              })
              : action.renderAction(props)
          )
        }
      </DownloadReport>
    );
  },
}];
